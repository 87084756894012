export default function Logo() {
  return (
    <svg version='1.1' viewBox='0 0 1000 1000'>
      <g transform='matrix(1.3333 0 0 -1.3333 0 1082.7)'>
        <g transform='matrix(1.4434 0 0 1.4433 -279.74 -281.28)'>
          <g transform='translate(428.64 575.44)'>
            <path
              d='m0 0c14.514 10.166 18.057 30.236 7.889 44.748-10.162 14.512-30.239 18.063-44.75 7.897-14.518-10.161-18.047-30.237-7.881-44.754 10.156-14.512 30.224-18.05 44.742-7.891'
              fill='#2489ca'
            />
          </g>
          <g transform='translate(583.04 549.08)'>
            <path
              d='m0 0c14.519 10.166 18.057 30.235 7.896 44.74-10.167 14.518-30.243 18.071-44.757 7.905-14.518-10.167-18.048-30.237-7.883-44.754 10.163-14.518 30.224-18.051 44.744-7.891'
              fill='#2489ca'
            />
          </g>
          <g transform='translate(555 394.96)'>
            <path
              d='m0 0c14.516 10.16 18.056 30.234 7.895 44.74-10.161 14.517-30.239 18.064-44.755 7.902-14.507-10.166-18.04-30.24-7.876-44.754 10.158-14.515 30.224-18.054 44.736-7.888'
              fill='#2489ca'
            />
          </g>
          <g transform='translate(666.4 646.68)'>
            <path
              d='m0 0c-82.307 117.55-244.31 146.12-361.84 63.803-78.35-54.852-117.1-145.1-109.91-234l160.45 112.34c-5.107 21.887 3.114 45.686 22.617 59.345 25.582 17.914 60.975 11.671 78.899-13.919 17.914-25.585 11.676-60.973-13.918-78.891-19.496-13.653-44.675-13.241-63.482-0.961l-176.73-123.76c5.905-22.125 14.907-43.736 26.97-64.279l280.06 196.1c-5.111 21.885 3.111 45.688 22.606 59.34 25.584 17.92 60.976 11.679 78.899-13.916 17.906-25.58 11.665-60.968-13.919-78.887-19.498-13.652-44.67-13.244-63.488-0.963l-280.06-196.11c15.179-18.361 32.405-34.216 51.186-47.328l176.73 123.74c-5.107 21.883 3.114 45.689 22.612 59.345 25.583 17.922 60.975 11.676 78.896-13.921 17.906-25.582 11.666-60.974-13.92-78.884-19.501-13.663-44.674-13.247-63.484-0.973l-160.44-112.35c81.078-37.144 179.12-31.592 257.46 23.258 117.54 82.305 146.11 244.33 63.809 361.86'
              fill='#2489ca'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
