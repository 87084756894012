import {
  Icon,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import NextLink, {LinkProps as NextLinkProps} from 'next/link'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {IconType} from 'react-icons/lib'

import {colors} from 'config/colors'

type LinkProps = ChakraLinkProps &
  NextLinkProps & {
    isDark?: boolean
  }

const LinkIcon = ({icon}: {icon: IconType}) => (
  <Icon
    as={icon}
    display='inline-block'
    ml='0.3rem'
    mt='-0.1rem'
    fontSize='0.6rem'
  />
)

export default function Link({
  children,
  isDark = false,
  isExternal = false,
  href = '',
  ...props
}: LinkProps) {
  const colorIndex = isDark ? 400 : 600
  const hoverColor = isDark ? 500 : 700
  const _isExternal = isExternal || href.startsWith('http')
  return (
    <NextLink href={href} passHref>
      <ChakraLink
        color={colors[colorIndex]}
        href={href}
        isExternal={_isExternal}
        _hover={{
          color: colors[hoverColor],
          textDecoration: 'underline'
        }}
        {...props}
      >
        {children}
        {_isExternal && <LinkIcon icon={FaExternalLinkAlt} />}
      </ChakraLink>
    </NextLink>
  )
}
