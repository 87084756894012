type Links = {
  title: string
  links: Conveyal.Link[]
}

type LinkGroup = {
  title: string
  links: (Links | Conveyal.Link)[]
}

export const SolutionsLinkGroup: LinkGroup = {
  title: 'Solutions',
  links: [
    {
      title: 'Public Sector',
      href: '/clients#massachusetts-department-of-transportation'
    },
    {
      title: 'Advocacy + NGOs',
      href: '/clients/#transportation-for-america'
    },
    {
      title: 'Consulting Services',
      href: '/contact'
    },
    {
      title: 'Education + Research',
      href: '/research'
    }
  ]
}

export const Resources: LinkGroup = {
  title: 'Resources',
  links: [
    {
      title: 'Demo',
      href: '/demo'
    },
    {
      title: 'Publications',
      href: '/research#conveyal-publications'
    },
    {
      title: 'User Manual',
      href: 'https://docs.conveyal.com'
    },
    {
      title: 'Independent Guidance',
      href: 'https://ssti.us/accessibility-analysis/'
    }
  ]
}

export const Company: LinkGroup = {
  title: 'Company',
  links: [
    {
      title: 'Blog',
      href: 'https://blog.conveyal.com'
    },
    {
      title: 'Github',
      href: 'https://github.com/conveyal'
    },
    {
      title: 'Twitter',
      href: 'https://twitter.com/conveyal'
    },
    {
      title: 'About Us',
      href: '/about'
    }
  ]
}

const AllGroups: LinkGroup[] = [SolutionsLinkGroup, Company]

export default AllGroups
