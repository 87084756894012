
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const content = {
  "callToAction": "Sign up",
  "contact": "Contact",
  "logIn": "Log in"
};

const layoutProps = {
  content
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;