
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const content = {
  "mailchimpSignupURL": "http://eepurl.com/gyNzEz",
  "newsletterTitle": "Conveyal Quarterly",
  "newsletterDescription": "Want to stay in touch? We send out a quarterly newsletter about our latest features, blog posts, and general updates to our community.",
  "newsletterSubmitButtonText": "Sign up for newsletter"
};

const layoutProps = {
  content
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;