import {Box, HStack, StackProps} from '@chakra-ui/react'
import NextLink from 'next/link'

import SVGLogo from './logo.svg'

const fontSizes = {base: '2xl', md: '3xl'}
const logoSizes = {base: '25px', md: '35px'}

export default function LogoWithText(props: StackProps) {
  return (
    <NextLink href='/'>
      <HStack alignContent='center' cursor='pointer' {...props}>
        <Box boxSize={logoSizes}>
          <SVGLogo />
        </Box>
        <Box>
          <Box
            fontSize={fontSizes}
            fontWeight={200}
            letterSpacing='-0.05rem'
            mt='-0.25rem'
          >
            conveyal
          </Box>
        </Box>
      </HStack>
    </NextLink>
  )
}
