import {
  Box,
  Button,
  Center,
  Heading,
  HeadingProps,
  Stack,
  Wrap,
  WrapItem
} from '@chakra-ui/react'

import * as LinkGroups from 'config/links'
import {space} from 'config/theme'
import {content} from 'content/footer.mdx'
import {FaExternalLinkAlt} from 'react-icons/fa'

import Link from './link'
import LogoWithText from './logo-text'

const LinkSections = [
  LinkGroups.SolutionsLinkGroup,
  LinkGroups.Resources,
  LinkGroups.Company
]

const SmallHeading = (props: HeadingProps) => (
  <Heading
    letterSpacing='wide'
    size='sm'
    textTransform='uppercase'
    {...props}
  />
)

const defSpacing = 16

const lgSpacing = {
  base: 10,
  md: 12,
  lg: defSpacing
}

export default function Footer() {
  return (
    <Center
      backgroundColor='blue.900'
      color='white'
      px={lgSpacing}
      py={defSpacing}
      width='100%'
    >
      <Stack width={{base: '100%', lg: 1200}} spacing={defSpacing}>
        <Wrap justify='space-between' spacing={defSpacing}>
          <WrapItem>
            <Box
              as='form'
              method='POST'
              action='/api/form/newsletter'
              maxWidth={{base: '100%', lg: '30ch', xl: '40ch'}}
            >
              <Stack spacing={space.md}>
                <SmallHeading>{content.newsletterTitle}</SmallHeading>
                <Box>{content.newsletterDescription}</Box>
                <Button
                  as='a'
                  color='blue.900'
                  href={content.mailchimpSignupURL}
                  rel='noopener'
                  rightIcon={<FaExternalLinkAlt />}
                  size='md'
                  target='_blank'
                >
                  {content.newsletterSubmitButtonText}
                </Button>
              </Stack>
            </Box>
          </WrapItem>
          {LinkSections.map((linkGroup, index) => (
            <WrapItem key={index}>
              <Stack
                key={index}
                spacing={space.xs}
                textAlign={{base: 'inherit', md: 'right'}}
              >
                <SmallHeading>{linkGroup.title}</SmallHeading>
                {linkGroup.links.map((item, index) =>
                  'href' in item ? (
                    <Box fontWeight='semibold' key={index}>
                      <Link isDark href={item.href}>
                        {item.title}
                      </Link>
                    </Box>
                  ) : (
                    <Stack key={index} spacing='xs'>
                      <SmallHeading>{item.title}</SmallHeading>
                      {item.links.map((link, index) => (
                        <Box fontWeight='semibold' key={index}>
                          <Link isDark href={link.href}>
                            {link.title}
                          </Link>
                        </Box>
                      ))}
                    </Stack>
                  )
                )}
              </Stack>
            </WrapItem>
          ))}
        </Wrap>
        <Wrap alignItems='center' justify='space-between'>
          <WrapItem>
            <SmallHeading opacity={0.75} size='xs' whiteSpace='nowrap'>
              Conveyal, LLC &copy; {new Date().getFullYear()}
            </SmallHeading>
          </WrapItem>
          <WrapItem>
            <LogoWithText color='white' />
          </WrapItem>
        </Wrap>
      </Stack>
    </Center>
  )
}
